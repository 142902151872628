import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const TeamsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Teams.mov"
      videoPosterUrl="/intros/Intro%20-%20Teams.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.teams.intro.tips.title"
            defaultMessage="Evaluate your teams and departments"
          />
        }
        text={
          <FormattedMessage
            id="onboardingV2.teams.intro.tips.text"
            defaultMessage="Review and adjust your current team and department structure as needed. Teams are a crucial foundation for defining areas of responsibility in your business and unlocking value throughout the platform."
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.teams.intro.tips.assign.title"
                defaultMessage="Assign owners"
              />
            ),
            icon: 'Services',
            text: (
              <FormattedMessage
                id="onboardingV2.teams.intro.tips.assign.text"
                defaultMessage="Every team and department requires an owner—picture this owner as the team's manager. It is imperative to assign owners for both teams and departments, as they will hold permissions to manage goals, talent, and performance reviews."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
