import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroList } from '@components/OnboardingIntro/OnboardingIntroList'
import { Text } from '@revolut/ui-kit'

export const ImportEmployeesIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Import%20Employees.mov"
      videoPosterUrl="/intros/Intro%20-%20Import%20Employees.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.employees.intro.title"
            defaultMessage="Get your team on board"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.employees.intro.text1"
            defaultMessage="To swiftly set up the system for your needs, begin by importing your employees. It's important to note that they won't be automatically invited; you can handle invitations manually later on."
          />
        </p>
        <FormattedMessage
          id="onboardingV2.employees.intro.text2"
          defaultMessage="To kickstart the process, we require a few key details from your employees:"
        />
        <OnboardingIntroList
          items={[
            {
              msg: (
                <FormattedMessage
                  id="onboarding.importEmployees.intro.list.email"
                  defaultMessage="email (unique identifier)"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.importEmployees.intro.list.team"
                  defaultMessage="team"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.importEmployees.intro.list.role"
                  defaultMessage="role"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.importEmployees.intro.list.seniority"
                  defaultMessage="seniority"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.importEmployees.intro.list.accessGroup"
                  defaultMessage="access group"
                />
              ),
            },
          ]}
        />
        <FormattedMessage
          id="onboardingV2.employees.intro.text3"
          defaultMessage="You can input this information manually or streamline the process by uploading a spreadsheet. Please note that the work email serves as the unique identifier for your employees."
        />
      </OnboardingIntroTextWidget>
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.importEmployees.intro.tips.title"
            defaultMessage="Tips for mastering your company structure"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.importEmployees.intro.tips.teams.title"
                defaultMessage="Teams & departments"
              />
            ),
            icon: 'Services',
            text: (
              <FormattedMessage
                id="onboardingV2.importEmployees.intro.tips.teams.text"
                defaultMessage="Think of teams and departments as groups or cohorts of individuals collaborating to achieve a shared objective, ultimately contributing to the overall goals of the department or company."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.importEmployees.intro.tips.roles.title"
                defaultMessage="Roles"
              />
            ),
            icon: 'RepairTool',
            text: (
              <FormattedMessage
                id="onboardingV2.importEmployees.intro.tips.roles.text"
                defaultMessage={`Consider roles as the essential "positions" required within a team to effectively reach its goals. Roles may vary in seniority levels, allowing employees to share a role while possessing different levels of experience and responsibility.`}
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.importEmployees.intro.tips.accessGroup.title"
                defaultMessage="Access group"
              />
            ),
            icon: 'Switches',
            text: (
              <FormattedMessage
                id="onboardingV2.importEmployees.intro.tips.accessGroup.text"
                defaultMessage="Every employee can be assigned varying access rights within the platform. We've provided pre-defined common options for you to choose from, and you can customise and adjust these settings later if required."
              />
            ),
          },
        ]}
      />

      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.employees.intro.title2"
            defaultMessage="Use defaults"
          />
        }
      >
        <FormattedMessage
          id="onboardingV2.employees.intro.text4"
          defaultMessage="Choose from our assortment of roles, teams, and departments, each with pre-defined skill sets based on different seniority levels. This streamlined approach ensures ease in utilizing performance and recruitment features later on."
        />

        <Text variant="h5" display="block" mb="s-8" mt="s-16">
          <FormattedMessage
            id="onboardingV2.employees.intro.title3"
            defaultMessage="Start"
          />
        </Text>

        <FormattedMessage
          id="onboardingV2.employees.intro.text5"
          defaultMessage="Begin by importing the essential employee details. You will have the opportunity in a later step to enrich this information with additional HR data (eg. salaries) or edit what you previously uploaded."
        />
      </OnboardingIntroTextWidget>
    </OnboardingIntro>
  )
}
