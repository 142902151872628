import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroList } from '@components/OnboardingIntro/OnboardingIntroList'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const CandidatesIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Candidates.mov"
      videoPosterUrl="/intros/Intro%20-%20Candidates.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.candidates.intro.title"
            defaultMessage="Create your candidates"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.candidates.intro.text1"
            defaultMessage="After adding your open jobs, you now have the opportunity to create candidate profiles from any pre-existing candidates you had in the hiring process."
          />
        </p>
        <FormattedMessage
          id="onboardingV2.candidates.intro.text2"
          defaultMessage="All of the essential candidate data is consolidated on the candidate profile. These details are:"
        />
        <OnboardingIntroList
          items={[
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.name"
                  defaultMessage="Candidate name"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.email"
                  defaultMessage="Email"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.role"
                  defaultMessage="Role"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.country"
                  defaultMessage="Country"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.seniority"
                  defaultMessage="Seniority"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.recruiter"
                  defaultMessage="Dedicated recruiter"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.posting"
                  defaultMessage="Job posting"
                />
              ),
            },
          ]}
        />

        <FormattedMessage
          id="onboardingV2.candidates.intro.text4"
          defaultMessage="You can add more information such as documents and salary expectations once the basic candidate profile has been created."
        />
      </OnboardingIntroTextWidget>

      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.candidates.intro.tips.title"
            defaultMessage="Tips for managing candidates"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.profile.title"
                defaultMessage="Candidate profile"
              />
            ),
            icon: 'Profile',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.profile.text"
                defaultMessage="The candidate profile is the hub for the essential information and key actions to manage your applicants. Here you can review the candidate timeline, send emails, schedule interviews and create tasks for other members of your team, all from a single place."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.add.title"
                defaultMessage="Adding new candidates"
              />
            ),
            icon: 'AddContact',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.add.text"
                defaultMessage="There are two methods for adding candidates; by bulk upload or manually. If you're in the beginning of the hiring process and only have a handful of applicants, consider adding them manually. Don’t worry if you make a mistake, candidate details can be edited after uploading."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.permissions.title"
                defaultMessage="Candidate profile permissions"
              />
            ),
            icon: 'Shield',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.permissions.text"
                defaultMessage="To view candidate information, you must be assigned as the recruiter, be the hiring manager, or be part of the Recruiter / Recruitment Manager access group. Profiles can be shared with other employees, ensuring everyone is aligned when hiring the top talent."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.sensitive.title"
                defaultMessage="Sensitive information"
              />
            ),
            icon: 'EyeShow',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.sensitive.text"
                defaultMessage="Certain information within the candidate profile may be sensitive. Fields such as Desired Salary or Right to Work status are accessible only to the members of the organisation actively involved in the hiring process of a specific candidate: the assigned recruiter and hiring manager."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.gdpr.title"
                defaultMessage="Data protection controls"
              />
            ),
            icon: 'LockClosed',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.gdpr.text"
                defaultMessage="You can set up data protection rules and anonymisation automations to ensure that your organisation remains compliant. Information about candidates can be deleted in the case a candidates requests for their data to be removed."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
