import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const JobsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Jobs.mov"
      videoPosterUrl="/intros/Intro%20-%20Jobs.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.jobs.intro.title"
            defaultMessage="Add your jobs"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.jobs.intro.text1"
            defaultMessage="Start the recruitment journey by creating jobs. You can use the bulk import feature to upload a single file with all your jobs or manually add them one by one."
          />
        </p>
        <p>
          <FormattedMessage
            id="onboardingV2.jobs.intro.text2"
            defaultMessage="Each job is comprised of position details such as the role, team and required headcount and a job description that will be published to job boards and your careers website to attract candidates."
          />
        </p>
        <p>
          <FormattedMessage
            id="onboardingV2.jobs.intro.text3"
            defaultMessage="Configure your careers website and preview how your job postings will look to prospective candidates. You can publish them to start receiving applications immediately or keep them unpublished for later use."
          />
        </p>
      </OnboardingIntroTextWidget>
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.jobs.intro.tips.title"
            defaultMessage="Jobs in a nutshell"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.requisitions.title"
                defaultMessage="Requisitions"
              />
            ),
            icon: 'AddContact',
            text: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.requisitions.text"
                defaultMessage="Each job is made up of two parts. First, there are requisitions. These serve as a headcount planning tool highlighting which new positions you need to fill. You can open a job posting without one but they are necessary to hire a candidate."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.postings.title"
                defaultMessage="Job postings"
              />
            ),
            icon: 'Megaphone',
            text: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.postings.text"
                defaultMessage="Then, there’s the job posting. This is the job advertisement you will share to attract candidates. You can keep job postings internal or share with the world. Application forms can be customised based on the job; making it easier to find the right talent."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.board.title"
                defaultMessage="Job boards and careers website"
              />
            ),
            icon: 'IndustrialGear',
            text: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.board.text"
                defaultMessage="Setup job board integrations to publish your job posting automatically to sites like LinkedIn. Published jobs will appear on your custom careers website too."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
