import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const DocumentsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Documents.mov"
      videoPosterUrl="/intros/Intro%20-%20Documents.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.documents.intro.tips.title"
            defaultMessage="Organise and manage all your documents securely"
          />
        }
        text={
          <FormattedMessage
            id="onboardingV2.documents.intro.tips.text"
            defaultMessage="Upload all employee documents and generate eSignatures."
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.upload.title"
                defaultMessage="Upload employee documents"
              />
            ),
            icon: 'Document',
            text: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.upload.text"
                defaultMessage="Keep all your employee documents in one central location, ensuring they're organised and easily accessible from an employee’s profile."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.request.title"
                defaultMessage="Request documents & signatures"
              />
            ),
            icon: 'People',
            text: (
              <FormattedMessage
                id="onboardingV2.documents.intro.tips.request.text"
                defaultMessage="Ask your employees to sign documents or upload files. Whether it's an NDA that requires everyone's signature or a passport image that all employees must upload, you can manage it all from a single platform."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
