import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroTextWidget } from '@src/components/OnboardingIntro/OnboardingIntroTextWidget'

export const PerformanceReviewsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Performance%20Reviews.mov"
      videoPosterUrl="/intros/Intro%20-%20Performance%20Reviews.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.reviews.intro.title"
            defaultMessage="Configure your reviews"
          />
        }
      >
        <FormattedMessage
          id="onboardingV2.reviews.intro.description"
          defaultMessage="Set the structure and criteria for how reviews should be completed. Leverage the tried and tested framework of deliverables, skills and values for a complete assessment. "
        />
      </OnboardingIntroTextWidget>

      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.reviews.intro.tips.title"
            defaultMessage="How assessments are built"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.goals.title"
                defaultMessage="Goals"
              />
            ),
            icon: 'Target',
            text: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.goals.text"
                defaultMessage="Deliverables can take the shape of goals or individual tasks which an employee was responsible for. Employees should be assessed against the overall progress of any goals they own, or on their impact towards the organisation’s goals which they contributed to."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.skills.title"
                defaultMessage="Skills"
              />
            ),
            icon: 'Palette',
            text: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.skills.text"
                defaultMessage="Each role contains a set of skills and these come with expected skill ratings for each seniority. Skills are reviewed by marking whether certain requirements have been met. This generates a rating which is compared to the expectations to determine an employee’s skill grading."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.values.text"
                defaultMessage="Cultural values"
              />
            ),
            icon: 'Heart',
            text: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.values.text"
                defaultMessage="Values are at the heart of company culture. Reviewers can assess how an employee aligns with company values and behaviours, rating whether they are performing above or below expectations within each; the average result of which determines the grade for this section."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.grades.title"
                defaultMessage="Determining performance grades"
              />
            ),
            icon: 'StarSemi',
            text: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.grades.text"
                values={{ br: <br /> }}
                defaultMessage="Employees are graded on a 5-point scale; Unsatisfactory through to Exceptional. The grading for each section of the review forms a recommendation for the employee. Recommendation are weighted 50% Goals, 25% Skills, and 25% Values.{br}{br}Managers should provide evidence and rationale for the grade given. Calibration can be conducted company wide to ensure consistency."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
