import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const RolesIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Roles.mov"
      videoPosterUrl="/intros/Intro%20-%20Roles.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.roles.intro.tips.title"
            defaultMessage="Evaluate your roles"
          />
        }
        text={
          <FormattedMessage
            id="onboardingV2.roles.intro.tips.text"
            defaultMessage="Review and adjust your roles as needed. Roles are essential for establishing individual expectations and are a foundational piece in the recruitment and performance features."
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.roles.intro.tips.assign.title"
                defaultMessage="Assign owners"
              />
            ),
            icon: 'Services',
            text: (
              <FormattedMessage
                id="onboardingV2.roles.intro.tips.assign.text"
                defaultMessage="Every role must be assigned an owner. Role owners are usually subject matter experts and should define the expectations of individuals within that role. They have permissions to manage the role’s skill matrix and should strive to keep the talent bar high."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
