import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroList } from '@components/OnboardingIntro/OnboardingIntroList'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const InterviewsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Interviews.mov"
      videoPosterUrl="/intros/Intro%20-%20Interviews.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.interviews.intro.title"
            defaultMessage="Configure your interview process"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.interviews.intro.text1"
            defaultMessage="Finally, it is time to put your interview process in place. A structured hiring process helps to reduce bias and increase hiring accuracy."
          />
        </p>
        <FormattedMessage
          id="onboardingV2.interviews.intro.text2"
          defaultMessage="Out of the box you’ll find a template hiring process:"
        />
        <OnboardingIntroList
          items={[
            {
              msg: (
                <FormattedMessage
                  id="onboarding.interviews.intro.list.cv"
                  defaultMessage="CV Screening"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.interviews.intro.list.screenCall"
                  defaultMessage="Screen Call"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.interviews.intro.list.skills"
                  defaultMessage="Skills Interview"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.interviews.intro.list.final"
                  defaultMessage="Final Interview"
                />
              ),
            },
          ]}
        />
        <FormattedMessage
          id="onboardingV2.interviews.intro.text3"
          defaultMessage="You can adjust this hiring process by customising stages, removing them or adding your own. Initially, this interview process will apply for every role. This can be adjusted later at the role level."
        />
      </OnboardingIntroTextWidget>

      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.interviews.intro.tips.title"
            defaultMessage="Components of interviews"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.process.title"
                defaultMessage="Interview process"
              />
            ),
            icon: '16/ListNumber',
            text: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.process.text"
                defaultMessage="You can apply a default hiring process to be used across the organisation or customise this process for each role individually. Each job is assigned to a specific role meaning all the candidates who applied to the same job posting will go through the same hiring process."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.stages.title"
                defaultMessage="Interview stages"
              />
            ),
            icon: 'Microphone',
            text: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.stages.text"
                defaultMessage="Each interview process is made up of stages. Each interview stage is characterised by a type, scorecard and defined interviewer(s). A template scorecard is generated based on the interview type. This can be customised afterwards to suit your needs."
                values={{
                  br: <br />,
                }}
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.scorecards.title"
                defaultMessage="Scorecards"
              />
            ),
            icon: 'Questionnaire',
            text: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.scorecards.text"
                defaultMessage="Scorecards contain the assessment questions and criteria for an interview. They are flexible and can be customised to align with your company’s hiring strategy and to capture data in a suitable way. Scorecards can be reused between different interview stages to help speed up setup of new interview processes."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.default.title"
                defaultMessage="Default interview stages"
              />
            ),
            icon: 'Chat',
            text: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.default.text"
                defaultMessage="We provide you with a default list of interview stages to help you get hiring quickly. Feel free to explore these in the settings and adjust them as needed. Here you can even add custom interview stages by clicking “Add interview stage” button."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
