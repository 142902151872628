import React, { ReactNode, Ref } from 'react'
import {
  Avatar,
  Box,
  chain,
  Flex,
  MoreBar,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import {
  DeliverableOptions,
  ReviewScorecardInterface,
  ReviewScorecardViewInterface,
  ReviewSummaryInterface,
  SkippedJustificationsInterface,
} from '@src/interfaces/performance'
import set from 'lodash/set'
import {
  BehaviourGradeOption,
  CardContentTypes,
  CommonGradeOption,
  DeliverableGradeOption,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'
import { relationToString } from '@src/features/Scorecard/constants'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Queries } from '@src/constants/api'
import { InfoOutline } from '@revolut/icons'
import { DeliverableScorecardInterface } from '@src/interfaces/deliverables'
import { AssessBehaviourButtonTypes } from '@components/AssessButtons/AssessBehaviourButtons'
import { CardItem } from '@src/pages/Forms/EmployeePerformanceLayout/CardItem'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'
import { notReachable } from '@src/utils/notReachable'

export interface CardField {
  field: string
  fieldToSelect?: string
  title: string
  titleButton?: React.ReactNode
  grades: CommonGradeOption[]
  cardIndex?: number
}

interface CardProps {
  data:
    | ReviewScorecardInterface
    | ReviewSummaryInterface
    | ReviewScorecardViewInterface
    | DeliverableScorecardInterface
  type: CardContentTypes
  title: string
  stat?: number
  icon: ResolveIconType
  fields: CardField[]
  isViewMode?: boolean
  onSelectDeliverableGrade?: (grade: DeliverableGradeOption, field: CardField) => void
  onSelectBehaviourGrade?: (grade: BehaviourGradeOption, field: CardField) => void
  justification?: string | SkippedJustificationsInterface[]
  isGradeSelectedRule?: (field: string, grade: CommonGradeOption) => boolean
  headerRef?: Ref<HTMLDivElement>
  finalRating?: string
  additionalInfo?: ReactNode
  actions?: ReactNode
  onHelpClick?: () => void
  renderExpandedContent: (selectedField: SelectedFieldInterface) => React.ReactNode
  renderExceedingContent?: (selectedField: SelectedFieldInterface) => React.ReactNode
}

export const Card = ({
  data,
  type,
  title,
  stat,
  icon,
  fields,
  isViewMode = false,
  onSelectDeliverableGrade,
  onSelectBehaviourGrade,
  justification,
  isGradeSelectedRule,
  headerRef,
  finalRating,
  additionalInfo,
  actions,
  onHelpClick,
  renderExpandedContent,
  renderExceedingContent,
}: CardProps) => {
  const { query } = useQuery()

  const onGradeClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    field: CardField,
    grade: CommonGradeOption,
  ) => {
    if (!isViewMode) {
      e.stopPropagation()
      if (onSelectDeliverableGrade || onSelectBehaviourGrade) {
        switch (grade.key) {
          case DeliverableOptions.DONT_KNOW:
          case DeliverableOptions.POOR:
          case DeliverableOptions.BASIC:
          case DeliverableOptions.INTERMEDIATE:
          case DeliverableOptions.ADVANCED:
          case DeliverableOptions.EXPERT:
            onSelectDeliverableGrade?.(grade, field)
            break
          case AssessBehaviourButtonTypes.positive:
          case AssessBehaviourButtonTypes.neutral:
          case AssessBehaviourButtonTypes.negative:
          case AssessBehaviourButtonTypes.unknown:
            onSelectBehaviourGrade?.(grade, field)
            break
          default:
            notReachable(grade)
        }
      } else {
        set(data, field.field, grade.key)
      }
    }
  }

  const justifications = Array.isArray(justification) ? justification : []
  const singleViewFilter = !!justifications.length && !!query[Queries.ReviewerId]

  return (
    <Widget p="s-16">
      <VStack ref={headerRef} space="s-16">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" gap="s-16" width="50%">
            <Avatar useIcon={icon} />
            <Flex alignItems="center" gap="s-8">
              <Box>
                {chain(
                  <Text variant="primary" fontWeight={400}>
                    {title}
                  </Text>,
                  stat ? (
                    <Text
                      variant="primary"
                      fontWeight={400}
                      color={getPercentColor(stat * 100)}
                    >
                      {formatPercentage(stat)}
                    </Text>
                  ) : undefined,
                )}
              </Box>
              {onHelpClick && (
                <InfoOutline
                  cursor="pointer"
                  size={16}
                  color={Token.color.greyTone50}
                  onClick={onHelpClick}
                />
              )}
            </Flex>
          </Flex>
          {finalRating && <Text variant="caption">{finalRating}</Text>}
        </Flex>
        {isViewMode && !!justifications.length && !singleViewFilter && (
          <VStack space="s-8">
            {justifications.map((value, i) => (
              <Box
                key={i}
                p="s-16"
                mx="s-16"
                mb={justifications.length === 1 ? 's-16' : 0}
                data-testid="skip-section"
                border={`1px solid ${Token.color.greyTone10}`}
                borderRadius={Token.radius.r16}
              >
                <Text variant="primary" use="div" mb="s-4">
                  {value.review.reviewer.display_name} (
                  {relationToString(value.review.reviewer_relation, true)}) skipped this
                  section
                </Text>
                <Text color="grey-tone-50">“{value.value}”</Text>
              </Box>
            ))}
          </VStack>
        )}
        {actions && <MoreBar>{actions}</MoreBar>}
        {additionalInfo}
        {(!isViewMode || !singleViewFilter) && (
          <>
            {fields.map((field, ind) => (
              <Box
                key={ind}
                border={`1px solid ${Token.color.grey8}`}
                borderRadius={Token.radius.r12}
                overflow="hidden"
              >
                <CardItem
                  data={data}
                  field={field}
                  isViewMode={isViewMode}
                  type={type}
                  cardItemIndex={ind}
                  onGradeClick={onGradeClick}
                  isGradeSelectedRule={isGradeSelectedRule}
                  renderExpandedContent={renderExpandedContent}
                  renderExceedingContent={renderExceedingContent}
                />
              </Box>
            ))}
          </>
        )}
      </VStack>
    </Widget>
  )
}
