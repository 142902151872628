import React, { useEffect, useState } from 'react'
import {
  Group,
  VStack,
  Link as UIKitLink,
  HStack,
  Tag,
  Token,
  Item,
  Avatar,
  ActionButton,
  BottomSheet,
  Header,
  InputGroup,
  Button,
  Box,
  Image,
  DetailsCell,
  useStatusPopup,
  StatusPopup,
  Text,
  TextButton,
  Icon,
} from '@revolut/ui-kit'
import { z } from 'zod'
import { useParams } from 'react-router-dom'
import pluralize from 'pluralize'

import { PageBody } from '@src/components/Page/PageBody'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import { AccountsSettingsInterface } from '@src/interfaces/settings'
import {
  TenantOnboardingProgress,
  TenantOnboardingSidebar,
  formatAdminName,
} from '../common'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { getAccountSettings } from '@src/api/settings'
import {
  getStringMessageFromError,
  pushNotification,
} from '@src/store/notifications/actions'
import {
  ERROR_DEFAULT_DURATION,
  SUCCESS_DEFAULT_DURATION,
} from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { useTenantOnboardingProgress, useTenantSubscriptionInfo } from '@src/api/tenants'
import {
  CustomPlanInterface,
  SubscriptionInfoInterface,
  UpdatePlanHeadcountInterface,
} from '@src/interfaces/plans'
import { formatMoney } from '@src/utils/format'
import {
  SUBSCRIPTION_CURRENCY,
  formatSubscriptionFreePeriod,
} from '@src/pages/Settings/PlanManagement/common'
import { useGetSelectors } from '@src/api/selectors'
import { OnboardingCheckpointSelectorInterface } from '@src/interfaces/onboardingChecklist'
import { selectorKeys } from '@src/constants/api'
import { createCustomPlanAndSubscription, updatePlannedHeadcount } from '@src/api/plans'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'

const REFRESH_STATUS_INTERVAL = 5000

export const Information = () => {
  const params = useParams()

  const { initialValues, values, reset } = useLapeContext<AccountsSettingsInterface>()

  const { data: subscriptionInfo, refetch } = useTenantSubscriptionInfo(values.id)

  const statusPopup = useStatusPopup()

  const [progressSidebarOpen, setProgressSidebarOpen] = useState(false)
  const [setupAnnualPlanPopupOpen, setSetupAnnualPlanPopupOpen] = useState(false)
  const [addSeatsPopupOpen, setAddSeatsPopupOpen] = useState(false)

  const onboardingCheckpoints = useGetSelectors<OnboardingCheckpointSelectorInterface>(
    selectorKeys.tenant_onboarding_checkpoints,
  )
  const onboardingProgress = useTenantOnboardingProgress(values.id)

  useEffect(() => {
    if (
      values.state === 'setup_failed' ||
      values.state === 'active' ||
      values.state === 'demo' ||
      values.state === 'closed'
    ) {
      return undefined
    }
    const refreshInterval = setInterval(async () => {
      const response = await getAccountSettings(values.id)

      if (response.data.state !== values.state) {
        if (response.data.state === 'active') {
          pushNotification({
            value: 'Account set to active',
            duration: SUCCESS_DEFAULT_DURATION,
            type: NotificationTypes.success,
          })
        }
        if (response.data.state === 'setup_failed') {
          pushNotification({
            value: 'Setup failed',
            duration: ERROR_DEFAULT_DURATION,
            type: NotificationTypes.error,
          })
        }
        reset(response.data)
      }
    }, REFRESH_STATUS_INTERVAL)

    return () => clearInterval(refreshInterval)
  }, [values.state])

  const onSetupAnnualPlanSuccess = () => {
    setSetupAnnualPlanPopupOpen(false)

    refetch()

    statusPopup.show(
      <StatusPopup variant="success-result" onClose={statusPopup.hide}>
        <StatusPopup.Title>Success</StatusPopup.Title>
        <StatusPopup.Description>
          {initialValues.company_name} Pricing Plan Updated
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button
            onClick={() => {
              statusPopup.hide()
              navigateTo(pathToUrl(ROUTES.SETTINGS.ACCOUNTS.INVOICES, params))
            }}
            variant="secondary"
          >
            View invoices
          </Button>
          <Button
            onClick={() => {
              statusPopup.hide()
              setSetupAnnualPlanPopupOpen(false)
            }}
            variant="secondary"
          >
            Close
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  return (
    <>
      <PageBody>
        <VStack space="s-24">
          {subscriptionInfo?.state.id === 'custom_plan_requested' ? (
            <Box mt="s-16">
              <Item>
                <Item.Avatar>
                  <Avatar useIcon="Bell" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>Annual plan requested</Item.Title>
                </Item.Content>
                <Item.Side>
                  <ActionButton onClick={() => setSetupAnnualPlanPopupOpen(true)}>
                    Set up plan
                  </ActionButton>
                </Item.Side>
              </Item>
            </Box>
          ) : null}

          <FormPreview title="Plan &amp; Billing" data={subscriptionInfo}>
            <Group>
              <FormPreview.Item
                title="Subscription date"
                field="subscribed_at"
                type="date"
              />
              <FormPreview.Item
                title="Plan"
                insert={() => {
                  if (subscriptionInfo?.subscription_plan?.manual_payment) {
                    return (
                      <VStack space="s-8" align="end">
                        <Text>{subscriptionInfo?.subscription_plan?.name}</Text>
                        <TextButton onClick={() => setAddSeatsPopupOpen(true)}>
                          <HStack align="center" space="s-8">
                            <Icon name="TurboTransfer" size={16} />
                            <Text>Add seats</Text>
                          </HStack>
                        </TextButton>
                      </VStack>
                    )
                  }
                  return subscriptionInfo?.subscription_plan?.name
                }}
              />
              <FormPreview.Item
                title="Value for the plan"
                insert={() =>
                  subscriptionInfo?.subscription_plan?.cycle_fee != null
                    ? `${formatMoney(
                        Number(subscriptionInfo.subscription_plan.cycle_fee),
                        SUBSCRIPTION_CURRENCY,
                      )}/active employee`
                    : '-'
                }
              />
              <FormPreview.Item
                title="Trial period"
                insert={() =>
                  subscriptionInfo?.subscription_plan?.free_days
                    ? formatSubscriptionFreePeriod(
                        subscriptionInfo.subscription_plan.free_days,
                      )
                    : '-'
                }
              />
              <FormPreview.Item<SubscriptionInfoInterface>
                title="Payment status for last billing cycle"
                field="last_payment_status.name"
                color={data => {
                  if (data.last_payment_status?.id === 'success') {
                    return Token.color.green
                  }
                  if (data.last_payment_status?.id === 'failure') {
                    return Token.color.red
                  }
                  return Token.color.foreground
                }}
              />
              <FormPreview.Item
                title="Payment details valid"
                insert={() =>
                  values.is_payment_method_set == null
                    ? '-'
                    : values.is_payment_method_set
                    ? 'Yes'
                    : 'No'
                }
              />
            </Group>
          </FormPreview>

          <FormPreview data={initialValues} title="Company info">
            <Group>
              <FormPreview.Item title="Company name" field="company_name" />
              <FormPreview.Item
                title="Company subdomain"
                insert={() => {
                  const value = `${values.subdomain}.${window.location.hostname}`
                  return (
                    <UIKitLink
                      href={`${window.location.protocol}//${value}`}
                      target="_blank"
                    >
                      {value}
                    </UIKitLink>
                  )
                }}
              />
              <FormPreview.Item title="Country" field="country.name" />
              <FormPreview.Item title="Headcount" field="active_headcount" />
              <FormPreview.Item title="VAT number" field="vat_number" />
              {values.labels.length > 0 ? (
                <FormPreview.Item
                  title="Labels"
                  insert={() => (
                    <HStack space="s-4">
                      {values.labels.map(label => (
                        <Tag
                          key={label}
                          variant="faded"
                          color="foreground"
                          bg={Token.color.greyTone8}
                        >
                          {label}
                        </Tag>
                      ))}
                    </HStack>
                  )}
                />
              ) : null}
              <FormPreview.Item title="Company ID" field="id" />
              <FormPreview.Item
                title="Onboarding progress"
                insert={() => (
                  <TenantOnboardingProgress
                    onSelectProgress={() => setProgressSidebarOpen(true)}
                    checkpointsSelector={onboardingCheckpoints.data}
                    progress={onboardingProgress}
                  />
                )}
              />
            </Group>
          </FormPreview>

          <FormPreview data={initialValues} title="Admin info">
            <Group>
              <FormPreview.Item title="Name" insert={formatAdminName} />
              <FormPreview.Item title="Email" field="admin.email" />
              <FormPreview.Item
                title="Phone number"
                insert={() => {
                  if (
                    !initialValues.admin ||
                    !initialValues.admin.phone_country_code ||
                    !initialValues.admin.phone_number_short
                  ) {
                    return '-'
                  }
                  return `${initialValues.admin.phone_country_code.code}${initialValues.admin.phone_number_short}`
                }}
              />
            </Group>
          </FormPreview>
        </VStack>
      </PageBody>

      <TenantOnboardingSidebar
        progress={onboardingProgress}
        open={progressSidebarOpen}
        onClose={() => setProgressSidebarOpen(false)}
      />

      <SetupAnnualPlanPopup
        id={values.id}
        open={setupAnnualPlanPopupOpen}
        onClose={() => setSetupAnnualPlanPopupOpen(false)}
        companyName={initialValues.company_name}
        onSuccess={onSetupAnnualPlanSuccess}
      />

      <AddSeatsPopup
        id={values.id}
        subscriptionId={subscriptionInfo?.subscription_plan?.id}
        open={addSeatsPopupOpen}
        onClose={() => setAddSeatsPopupOpen(false)}
        onSuccess={onSetupAnnualPlanSuccess}
      />
    </>
  )
}

interface SetupAnnualPlanPopupProps {
  id: string | number
  open: boolean
  onClose: () => void
  companyName?: string
  onSuccess: () => void
}

const SetupAnnualPlanPopup = ({
  id,
  open,
  onClose,
  companyName,
  onSuccess,
}: SetupAnnualPlanPopupProps) => {
  const statusPopup = useStatusPopup()

  return (
    <>
      <BottomSheet open={open} onClose={onClose}>
        <Header>
          <Header.Title>Set Up Plan</Header.Title>
          {companyName ? (
            <Header.Description>Setup plan for {companyName}</Header.Description>
          ) : null}
        </Header>

        <LapeForm<CustomPlanInterface>
          onSubmit={form => {
            return createCustomPlanAndSubscription(id, form.values)
              .then(resp => {
                onSuccess()
                return resp.data
              })
              .catch(error => {
                statusPopup.show(
                  <StatusPopup variant="error">
                    <StatusPopup.Title>Failed to setup pricing plan</StatusPopup.Title>
                    <StatusPopup.Description>
                      {getStringMessageFromError(error)}
                    </StatusPopup.Description>
                    <StatusPopup.Actions>
                      <Button elevated onClick={statusPopup.hide} variant="secondary">
                        Close
                      </Button>
                    </StatusPopup.Actions>
                  </StatusPopup>,
                )
              })
          }}
        >
          <FormValidatorProvider>
            <SetupAnnualPlanForm companyName={companyName} onClose={onClose} />
          </FormValidatorProvider>
        </LapeForm>
      </BottomSheet>
    </>
  )
}

const validateAnnualPlanForm = (values: unknown) =>
  z
    .object({
      planned_headcount: z.coerce.number().gt(0),
      cycle_fee: z
        .string()
        .min(1)
        .refine(value => value === null || !isNaN(Number(value)))
        .transform(value => (value === null ? null : Number(value))),
      cycle_period_days: z.coerce.number().gt(0),
    })
    .safeParse(values)

const getTotalAmount = (fee?: string, plannedHeadcount?: string) => {
  const monthlyFee = Number(fee)
  const headcount = Number(plannedHeadcount)

  if (isNaN(monthlyFee) || isNaN(headcount)) {
    return '-'
  }
  return formatMoney(monthlyFee * headcount, 'GBP')
}

interface SetupAnnualPlanFormProps {
  companyName?: string
  onClose: () => void
}

const SetupAnnualPlanForm = ({ companyName, onClose }: SetupAnnualPlanFormProps) => {
  const { values, submit, isSubmitting } = useLapeContext<CustomPlanInterface>()

  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false)

  const isValidForm = validateAnnualPlanForm(values).success

  useEffect(() => {
    values.name = 'Custom annual plan'
    values.description =
      'Perfect for companies of all sizes. For all your HR, Recruitment and Performance needs'
    values.cycle_period_days = 365
  }, [])

  return (
    <>
      <InputGroup>
        <LapeNewInput name="name" label="Name" required />
        <LapeNewTextArea name="description" label="Description" required />
        <LapeNewInput
          name="planned_headcount"
          label="Number of seats"
          message="Number of users purchased"
          required
          type="number"
        />
        <LapeNewInput
          name="cycle_fee"
          label="Price per seat, GBP"
          type="number"
          required
        />
        <DetailsCell>
          <DetailsCell.Title>Total amount</DetailsCell.Title>
          <DetailsCell.Content>
            {getTotalAmount(values?.cycle_fee, values?.planned_headcount)}
          </DetailsCell.Content>
        </DetailsCell>
        <LapeNewInput
          name="cycle_period_days"
          label="Duration (days)"
          type="number"
          required
        />
      </InputGroup>

      <BottomSheet.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button onClick={() => setConfirmationPopupOpen(true)} disabled={!isValidForm}>
          Set up
        </Button>
      </BottomSheet.Actions>

      <BottomSheet
        open={confirmationPopupOpen}
        onClose={() => setConfirmationPopupOpen(false)}
      >
        <Header>
          <Header.Title>Attention</Header.Title>
          <Header.Actions>
            <Image
              size={64}
              src="https://assets.revolut.com/assets/3d-images-v2/3D018.png"
              srcSet="https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png 2x, https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png 3x"
              alt="Attention sign"
            />
          </Header.Actions>
          <Header.Description>
            Setting up this plan will enrol the customer onto the plan immediately. Are
            you happy to proceed?
          </Header.Description>
        </Header>

        <Group>
          <DetailsCell>
            <DetailsCell.Title>Tenant</DetailsCell.Title>
            <DetailsCell.Content>{companyName}</DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Number of seats</DetailsCell.Title>
            <DetailsCell.Content>{values?.planned_headcount}</DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Price per seat, GBP</DetailsCell.Title>
            <DetailsCell.Content>
              {formatMoney(values?.cycle_fee, 'GBP')}
            </DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Total amount</DetailsCell.Title>
            <DetailsCell.Content>
              {getTotalAmount(values?.cycle_fee, values.planned_headcount)}
            </DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Duration (days)</DetailsCell.Title>
            <DetailsCell.Content>
              {pluralize('day', values?.cycle_period_days, true)}
            </DetailsCell.Content>
          </DetailsCell>
        </Group>

        <BottomSheet.Actions horizontal>
          <Button onClick={() => setConfirmationPopupOpen(false)} variant="secondary">
            Back
          </Button>
          <Button onClick={() => submit()} pending={isSubmitting}>
            Set up
          </Button>
        </BottomSheet.Actions>
      </BottomSheet>
    </>
  )
}

interface AddSeatsPopupProps {
  id: string | number
  subscriptionId?: number
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

const AddSeatsPopup = ({
  id,
  subscriptionId,
  open,
  onClose,
  onSuccess,
}: AddSeatsPopupProps) => {
  if (!subscriptionId) {
    return null
  }

  return (
    <>
      <BottomSheet open={open} onClose={onClose}>
        <Header>
          <Header.Title>Set Up Additional Seats</Header.Title>
        </Header>

        <LapeForm<UpdatePlanHeadcountInterface>
          onSubmit={form => {
            return updatePlannedHeadcount(id, form.values).then(resp => {
              onSuccess()
              return resp.data
            })
          }}
        >
          <FormValidatorProvider>
            <AddSeatsPopupForm onClose={onClose} />
          </FormValidatorProvider>
        </LapeForm>
      </BottomSheet>
    </>
  )
}

const validateAddSeatsForm = (values: unknown) =>
  z
    .object({
      planned_headcount: z.coerce.number().gt(0),
      fee: z
        .string()
        .min(1)
        .refine(value => value === null || !isNaN(Number(value)))
        .transform(value => (value === null ? null : Number(value))),
      effective_date: z.string().min(1),
    })
    .safeParse(values)

interface AddSeatsPopupFormProps {
  onClose: () => void
}

const AddSeatsPopupForm = ({ onClose }: AddSeatsPopupFormProps) => {
  const { values, submit, isSubmitting } = useLapeContext<UpdatePlanHeadcountInterface>()
  const { validate } = useSafeFormValidator()
  const statusPopup = useStatusPopup()

  const isValidForm = validateAddSeatsForm(values).success

  return (
    <>
      <InputGroup>
        <LapeNewInput
          name="planned_headcount"
          label="Number of additional seats"
          required
          type="number"
        />
        <LapeNewInput name="fee" label="Price per seat, GBP" type="number" required />
        <DetailsCell>
          <DetailsCell.Title>Total amount</DetailsCell.Title>
          <DetailsCell.Content>
            {getTotalAmount(values.fee, values.planned_headcount)}
          </DetailsCell.Content>
        </DetailsCell>
        <LapeDatePickerInput name="effective_date" label="Effective date" required />
      </InputGroup>

      <BottomSheet.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={validate(
            () => submit(),
            error => {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>Failed to add seats</StatusPopup.Title>
                  <StatusPopup.Description>
                    {getStringMessageFromError(error)}
                  </StatusPopup.Description>
                  <StatusPopup.Actions>
                    <Button elevated onClick={statusPopup.hide} variant="secondary">
                      Close
                    </Button>
                  </StatusPopup.Actions>
                </StatusPopup>,
              )
            },
          )}
          disabled={!isValidForm}
          pending={isSubmitting}
        >
          Set up
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
