import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroList } from '@components/OnboardingIntro/OnboardingIntroList'

export const GoalsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Goals.mov"
      videoPosterUrl="/intros/Intro%20-%20Goals.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.goals.intro.title"
            defaultMessage="Setup your goals"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.goals.intro.text1"
            defaultMessage="Begin your performance journey by defining clear, actionable goals. These objectives are crucial for steering employees' efforts in alignment with the company’s strategic direction."
          />
        </p>
        <FormattedMessage
          id="onboardingV2.goals.intro.text2"
          defaultMessage="To establish a strong goal framework, we'll need some critical details:"
        />
        <OnboardingIntroList
          items={[
            {
              msg: (
                <FormattedMessage
                  id="onboarding.goals.intro.list.name"
                  defaultMessage="Goal name"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.goals.intro.list.description"
                  defaultMessage="Description"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.goals.intro.list.metric"
                  defaultMessage="Metric"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.goals.intro.list.roadmap"
                  defaultMessage="Roadmap (optional)"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.goals.intro.list.analytics"
                  defaultMessage="Link to analytics (optional)"
                />
              ),
            },
          ]}
        />
        <FormattedMessage
          id="onboardingV2.goals.intro.text3"
          defaultMessage="You can enter this information manually, import it via an integration with your existing task management tools, or upload a file. Remember, the goal name is used as the primary identifier for tracking and assessment purposes."
        />
      </OnboardingIntroTextWidget>
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboarding.goals.intro.tips.title"
            defaultMessage="Understand goals hierarchy"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboarding.goals.intro.tips.companyGoals.title"
                defaultMessage="Company goals"
              />
            ),
            icon: 'Bank',
            text: (
              <FormattedMessage
                id="onboarding.goals.intro.tips.companyGoals.text"
                defaultMessage="Company goals are strategic, focusing on long-term outcomes such as increasing market share or achieving financial targets.They are designed to provide a clear vision for the company's future and to align all subsequent goal-setting efforts."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboarding.goals.intro.tips.teamGoals.title"
                defaultMessage="Department/Team goals"
              />
            ),
            icon: 'People',
            text: (
              <FormattedMessage
                id="onboarding.goals.intro.tips.teamGoals.text"
                defaultMessage="Department or team goals are more specific objectives that align with the company's overarching goals. These goals ensure that each department or team contributes effectively to the company's broader objectives while working within their specialised areas."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboarding.goals.intro.tips.employeeGoals.title"
                defaultMessage="Employee goals"
              />
            ),
            icon: 'Profile',
            text: (
              <FormattedMessage
                id="onboarding.goals.intro.tips.employeeGoals.text"
                defaultMessage="Employee goals are tailored to an individual's role, responsibilities, or professional development. Employee goals provide ownership and help individuals understand how their work contributes to the organisation."
              />
            ),
          },
        ]}
      />
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboarding.goals.intro.tips.integrations.title"
            defaultMessage="Automate updates with integrations"
          />
        }
        text={
          <FormattedMessage
            id="onboarding.goals.intro.tips.integrations.text"
            defaultMessage="Goals can be integrated with project management and business intelligence platforms to provide real-time progress and metric updates. Data synchronisation from these tools helps to reduce manual admin."
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboarding.goals.intro.tips.jira.title"
                defaultMessage="Jira"
              />
            ),
            icon: 'Jira|image',
            text: (
              <FormattedMessage
                id="onboarding.goals.intro.tips.jira.text"
                defaultMessage="Connect your Jira instance to sync your project tasks and milestones. This integration allows for effortless tracking of progress against goals; providing a comprehensive view of team or individual achievements"
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboarding.goals.intro.tips.looker.title"
                defaultMessage="Looker"
              />
            ),
            icon: {
              url: 'https://assets.revolut.com/assets/apps/Looker@2x.png',
            },
            text: (
              <FormattedMessage
                id="onboarding.goals.intro.tips.looker.text"
                defaultMessage="Integrate with Looker to auto updated metrics and bring powerful analytics into your system. Act faster with the data at your fingertips and visualise progress with embedded dashboards and reports."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
